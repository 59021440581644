'use client'

import { useState } from 'react'

const parseValue = (value: any) => {
  if (typeof value !== 'string') return value

  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

const stringifyValue = (value: any) => {
  if (typeof value === 'string') return value

  try {
    return JSON.stringify(value)
  } catch {
    return ''
  }
}

export function useLocalStorage<D = null>(
  key: string,
  defaultValue: D
): [D, (newValue: D) => void] {
  const savedValue =
    typeof window === 'object' ? localStorage.getItem(key) : undefined
  const [value, setValue] = useState<D>(parseValue(savedValue) ?? defaultValue)

  const updateValue = (newValue: D) => {
    setValue((prevValue) => {
      let evaluatedValue = newValue
      if (typeof newValue === 'function') {
        evaluatedValue = newValue(prevValue)
      }

      const parsedValue = parseValue(evaluatedValue)

      localStorage.setItem(key, stringifyValue(parsedValue))
      return parsedValue
    })
  }

  return [value, updateValue]
}
