'use client'

import React from 'react'
import './popular-questions.scss'

interface IPopularQuestionsProps {
  onSelect: (value: string) => void
  questions: string[]
}

export default function PopularQuestions({
  onSelect,
  questions,
}: IPopularQuestionsProps) {
  if (!questions?.length) return null

  // TODO: Add tracking
  return (
    <div className='popularQuestions'>
      <p className='popularQuestions__title'>Suggested:</p>
      <ul className='popularQuestions__list'>
        {questions.map((question) => (
          <li
            className='popularQuestions__item'
            key={question + Math.floor(Math.random() * 1000)}
          >
            <button
              type='button'
              data-testid='chatbot-suggestion-btn'
              className='popularQuestions__question'
              onClick={() => onSelect(question)}
            >
              {question}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
