import Markdown from '../Markdown/Markdown.client'
import './chat-modal.scss'

interface IChatModal {
  onClose: () => void
  modalTitle?: string
  modalContent?: string
  modalButton?: string
}

export default function ChatModal({
  onClose,
  modalTitle,
  modalContent,
  modalButton,
}: IChatModal) {
  const confirm = () => {
    onClose()
  }

  return (
    <div className='chatModal' data-testid='chatbot-modal'>
      {modalTitle && <h1 className='chatModal__title'>{modalTitle}</h1>}
      <div className='chatModal__content'>
        <Markdown>{modalContent}</Markdown>
      </div>
      <div className='chatModal__cta'>
        <button data-testid='chatbot-modal-btn' type='button' onClick={confirm}>
          {modalButton}
        </button>
      </div>
    </div>
  )
}
