'use client'

import Markdown from '../Markdown/Markdown.client'
import './user-chat-bubble.scss'

interface IUserChatBubbleProps {
  children: React.ReactNode
  className?: string
}

export default function UserChatBubble({ children, className }: IUserChatBubbleProps) {
  // TODO: track impression
  return (
    <div
      className={`userChatBubble ${className ?? ''}`}
      data-testid='chatbot-user-msg'
    >
      <div className='userChatBubble__message'>
        <Markdown>
          {children}
        </Markdown>
      </div>
      <span className='userChatBubble__avatar'>
        You
      </span>
    </div>
  )
}
