/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgLoading({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-loading'
      fill='currentColor'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      role='img'
      aria-label='Loading'
    >
      <path d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'>
        <animateTransform
          attributeType='xml'
          attributeName='transform'
          type='rotate'
          from='0 25 25'
          to='360 25 25'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </path>
    </svg>
  )
}
