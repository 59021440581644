'use client'

import SvgCopyText from 'app/components/Svg/SvgCopyText'
import SvgThumbsDown from 'app/components/Svg/SvgThumbsDown'
import SvgThumbsUp from 'app/components/Svg/SvgThumbsUp'
import { useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import Markdown from '../Markdown/Markdown.client'
import FeedbackModal from './FeedbackModal.client'
import './response-chat-bubble.scss'

interface IResponseChatBubbleProps {
  children: React.ReactNode
  className?: string
  hideActionBlock?: boolean
  responseId: string
}

enum Feedback {
  MEH,
  GOOD,
  BAD,
}

export default function ResponseChatBubble({
  children,
  className,
  hideActionBlock,
  responseId,
}: IResponseChatBubbleProps) {
  const [feedback, setFeedback] = useState<Feedback>(Feedback.MEH)
  const [copied, setCopied] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [surveyId, setSurveyId] = useState('')
  const [feedbackId, setFeedbackId] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const cookies = new Cookies()
  const accessToken: string = cookies.get('user-jwt')

  const trackCopy = () => {
    // TODO track copy
  }

  const copyText = () => {
    const element = ref.current
    if (!element) return

    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(element)
    selection?.removeAllRanges()
    selection?.addRange(range)
    // execCommand is deprecated but I couldn't find another solution that copies links as well
    document.execCommand('copy')
    selection?.removeAllRanges()

    trackCopy()
    setCopied(true)
    setTimeout(() => setCopied(false), 5000)
  }

  const startSurvey = async (surveyKey: string) => {
    const response = await fetch('/api/chat/bho/start', {
      method: 'POST',
      body: JSON.stringify({
        jwt: accessToken,
        surveyKey,
      }),
    })
    return response.json()
  }

  const updateSurvey = async (sId: string, surveyKey: string) => {
    const response = await fetch('/api/chat/bho/update', {
      method: 'PUT',
      body: JSON.stringify({
        jwt: accessToken,
        surveyKey,
        surveyId: sId,
        data: {
          responseId,
        },
      }),
    })
    return response.json()
  }

  const sendToLangsmith = async (
    traceId: string,
    feedbackScore: 0 | 1
  ): Promise<string> => {
    const response = await fetch('/api/chat/feedback', {
      method: 'POST',
      body: JSON.stringify({
        traceId,
        feedback: feedbackScore,
      }),
    })

    const res = await response.json()
    return res.feedbackId
  }

  const onThumbsUpOnClick = async () => {
    setFeedback(Feedback.GOOD)

    await sendToLangsmith(responseId, 1)

    const surveyKey = 'chatbot_thumbs_up'
    const start = await startSurvey(surveyKey)
    if (start.surveyId) {
      await updateSurvey(start.surveyId, surveyKey)
    }

    // TODO: track thumbs up
  }

  const onThumbsdownClick = async () => {
    setFeedback(Feedback.BAD)
    setShowFeedbackModal(true)

    const response = await sendToLangsmith(responseId, 0)
    setFeedbackId(response)

    const surveyKey = 'chatbot_thumbs_down'
    const start = await startSurvey(surveyKey)
    if (start.surveyId) {
      setSurveyId(start.surveyId)
      await updateSurvey(start.surveyId, surveyKey)
    }

    // TODO: track thumbs down
  }

  useEffect(() => {
    const divElement = ref.current
    if (!divElement) return

    divElement.addEventListener('copy', trackCopy)

    return () => {
      divElement.removeEventListener('copy', trackCopy)
    }
  }, [ref])

  // TODO: track impression
  return (
    <div className={`responseChatBubble ${className ?? ''}`}>
      <div
        ref={ref}
        id={responseId}
        data-testid='chatbot-bot-msg'
        className={`responseChatBubble__message
          ${hideActionBlock && 'responseChatBubble__message--noActionBlock'}`}
      >
        <Markdown>{children}</Markdown>
      </div>
      {!hideActionBlock && (
        <div
          className='responseChatBubble__actionBlock'
          data-testid='chatbot-action-block'
        >
          <button
            className={`responseChatBubble__actionBlock__button
              ${copied && 'responseChatBubble__actionBlock__button--copied'}`}
            onClick={copyText}
            type='button'
            aria-label='Copy text'
          >
            <SvgCopyText className='responseChatBubble__actionBlock__button__icon' />
            {copied && (
              <div className='responseChatBubble__actionBlock__button__tip'>
                text copied
              </div>
            )}
          </button>
          <button
            className={`responseChatBubble__actionBlock__button
              ${feedback === Feedback.GOOD && 'responseChatBubble__actionBlock__button--good'}`}
            onClick={onThumbsUpOnClick}
            disabled={feedback !== Feedback.MEH}
            type='button'
            aria-label='Thumbs up'
          >
            <SvgThumbsUp className='responseChatBubble__actionBlock__button__icon' />
          </button>
          <button
            className={`responseChatBubble__actionBlock__button
              ${feedback === Feedback.BAD && 'responseChatBubble__actionBlock__button--bad'}`}
            onClick={onThumbsdownClick}
            disabled={feedback !== Feedback.MEH}
            type='button'
            aria-label='Thumbs down'
          >
            <SvgThumbsDown className='responseChatBubble__actionBlock__button__icon' />
          </button>
        </div>
      )}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        responseId={responseId}
        surveyId={surveyId}
        feedbackId={feedbackId}
      />
    </div>
  )
}
