/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgSend({ className }: ISvgProps) {
  return (
    <svg
      viewBox='0 0 16 14'
      fill='currentColor'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      data-testid='svg-send'
      role='img'
      aria-label='Send message'
    >
      <path d='M1.7135 0.101769L15.2642 5.89809C16.2453 6.32074 16.2453 7.67926 15.2642 8.10191L1.7135 13.8982C0.671132 14.3511 -0.401888 13.2039 0.149951 12.2076L2.29599 8.31323C2.41862 8.07172 2.66389 7.89058 2.97046 7.86039L8.36622 7.19623C8.4582 7.19623 8.55017 7.10566 8.55017 6.98491C8.55017 6.89434 8.4582 6.80377 8.36622 6.80377L2.97046 6.13961C2.66389 6.07923 2.41862 5.92828 2.29599 5.68677L0.149951 1.79236C-0.401888 0.79612 0.671132 -0.351069 1.7135 0.101769Z' />
    </svg>
  )
}
