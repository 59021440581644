'use client'

import React from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import './markdown.scss'

function MarkdownComponents(): Components {
  return {
    a(anchorProps) {
      const { children, href, ...rest } = anchorProps
      // TODO: Add tracking
      return (
        <a
          {...rest}
          href={href}
          className='markdown__link'
          target='blank'
          rel='noopener'
        >
          {children}
        </a>
      )
    },
  }
}

interface IMarkdownProps {
  children: React.ReactNode
}

export default function Markdown({ children }: IMarkdownProps) {
  if (typeof children !== 'string') {
    return <div className='markdown'>{children}</div>
  }

  return (
    <ReactMarkdown className='markdown' components={MarkdownComponents()}>
      {children}
    </ReactMarkdown>
  )
}
