/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgThumbsDown({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-thumbs-down'
      fill='currentColor'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
      role='img'
      aria-label='Thumbs down'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 0V6.99998H11.2L8.4 11.1999V14H7C4.2 13.9999 4.2 12.5999 4.2 9.79998H2.8C1.4 9.79998 0 8.34395 0 6.99998V4.1999C0 1.3999 1.4 0 4.2 0H14ZM7 11.1999V12.6C6.3957 12.6 6.0914 12.521 5.94987 12.4581C5.857 12.4168 5.83403 12.3875 5.8022 12.3238C5.74512 12.2097 5.6772 11.9806 5.63891 11.5212C5.60102 11.0664 5.6 10.5181 5.6 9.79998C5.6 9.02678 4.9732 8.39998 4.2 8.39998H2.8C2.57228 8.39998 2.21111 8.26476 1.87149 7.9206C1.52936 7.57392 1.4 7.2086 1.4 6.99998V4.1999C1.4 3.0157 1.6951 2.38474 2.03993 2.03992C2.38477 1.69509 3.01578 1.4 4.2 1.4H9.8V6.57609L7.23513 10.4233C7.08181 10.6533 7 10.9235 7 11.1999ZM11.2 5.59998V1.4H12.6V5.59998H11.2Z'
      />
    </svg>
  )
}
