import { InputHTMLAttributes } from 'react'
import './checkbox.scss'

export default function Checkbox({
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input {...props} type='checkbox' className={`checkbox ${className ?? ''}`} />
  )
}
