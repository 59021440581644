/* eslint max-len: 0 */

import { ISvgProps } from './Svg.interface'

export default function SvgCopyText({ className }: ISvgProps) {
  return (
    <svg
      className={className}
      data-testid='svg-copy-text'
      fill='currentColor'
      viewBox='0 0 14 16'
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      focusable='false'
      role='img'
      aria-label='Copy text'
    >
      <path
        d='M10.1818 0H1.27273C0.935282 0.000325832 0.611755 0.1301 0.373145 0.360844C0.134536 0.591587 0.00033694 0.904449 0 1.23077V12.3077C0.00033694 12.634 0.134536 12.9469 0.373145 13.1776C0.611755 13.4084 0.935282 13.5381 1.27273 13.5385H10.1818C10.5192 13.5381 10.8427 13.4083 11.0813 13.1776C11.3199 12.9468 11.4542 12.634 11.4545 12.3077V1.23077C11.4542 0.904463 11.3199 0.591629 11.0813 0.360896C10.8427 0.130163 10.5192 0.00037298 10.1818 0ZM1.27273 12.3077V1.23077H10.1818L10.1825 12.3077H1.27273Z'
      />
      <path
        d='M13.9998 6.1539C13.9998 5.99069 13.9328 5.83416 13.8134 5.71876C13.6941 5.60335 13.5322 5.53851 13.3635 5.53851C13.1947 5.53851 13.0328 5.60335 12.9135 5.71876C12.7941 5.83416 12.7271 5.99069 12.7271 6.1539V14.1539C12.7269 14.3171 12.6598 14.4735 12.5405 14.5889C12.4212 14.7042 12.2595 14.7691 12.0907 14.7693H3.818C3.64923 14.7693 3.48737 14.8341 3.36803 14.9495C3.24869 15.0649 3.18164 15.2215 3.18164 15.3847C3.18164 15.5479 3.24869 15.7044 3.36803 15.8198C3.48737 15.9352 3.64923 16.0001 3.818 16.0001H12.0907C12.5969 15.9995 13.0821 15.8048 13.4401 15.4587C13.798 15.1126 13.9993 14.6434 13.9998 14.1539V6.1539Z'
      />
    </svg>
  )
}
