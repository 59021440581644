'use client'

import { InputHTMLAttributes, useEffect, useRef } from 'react'
import './text-area.scss'

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  rows?: number
  autoFit?: boolean
}

export default function TextArea({
  autoFit,
  ...props
}: ITextAreaProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  // Dynamically adjust height to fit content
  useEffect(() => {
    if (autoFit && inputRef.current) {
      inputRef.current.style.height = 'auto'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [props.value, autoFit])

  return (
    <textarea
      {...props}
      ref={inputRef}
      className={`textArea ${props.className}`}
    />
  )
}
