'use client'

import { useEffect, useRef, useState } from 'react'
import SvgThumbsDown from 'app/components/Svg/SvgThumbsDown'
import SvgClose from 'app/components/Svg/SvgClose'
import Cookies from 'universal-cookie'
import SvgLoading from 'app/components/Svg/SvgLoading'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import Checkbox from '../Checkbox/Checkbox'
import TextArea from '../TextArea/TextArea.client'

interface IFeedbackModalProps {
  isOpen: boolean
  onClose: () => void
  feedbackId: string
  surveyId: string
  responseId: string
}

const feedbackOptions = {
  notHelpful: 'THIS_IS_NOT_HELPFUL|',
  notTrue: 'THIS_IS_NOT_TRUE|',
  harmful: 'THIS_IS_HARMFUL_UNSAFE|',
} as const

export default function FeedbackModal({
  isOpen,
  onClose,
  feedbackId,
  surveyId,
  responseId,
}: IFeedbackModalProps) {
  const cookies = new Cookies()
  const modalRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [checkboxValues, setCheckboxValues] = useState<
    Record<keyof typeof feedbackOptions, boolean>
  >({
    notHelpful: false,
    notTrue: false,
    harmful: false,
  })

  useEffect(() => {
    if (!isOpen) return

    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        onClose()
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  const handleCheckboxChange = (key: keyof typeof checkboxValues) => {
    setCheckboxValues((prev) => ({
      ...checkboxValues,
      [key]: !prev[key],
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // TODO: track engagement

    let checkboxFeedback = ''
    if (checkboxValues.harmful) {
      checkboxFeedback = checkboxFeedback.concat(feedbackOptions.harmful)
    }
    if (checkboxValues.notHelpful) {
      checkboxFeedback = checkboxFeedback.concat(feedbackOptions.notHelpful)
    }
    if (checkboxValues.notTrue) {
      checkboxFeedback = checkboxFeedback.concat(feedbackOptions.notTrue)
    }

    const promiseUpdateLS = fetch('/api/chat/feedback', {
      method: 'PUT',
      body: JSON.stringify({
        feedbackId,
        comment: `${checkboxFeedback}${inputValue}`,
      }),
    })

    const promiseUpdateBHO = fetch('/api/chat/bho/update', {
      method: 'PUT',
      body: JSON.stringify({
        jwt: cookies.get('user-jwt'),
        surveyId,
        surveyKey: 'chatbot_thumbs_down',
        data: {
          responseId,
          feedback: checkboxFeedback,
          comment: inputValue,
        },
      }),
    })

    setSubmitting(true)
    await Promise.all([promiseUpdateLS, promiseUpdateBHO])

    onClose()
  }

  if (!isOpen) {
    return null
  }

  return createPortal(
    <>
      <FocusTrap>
        <div
          className='feedbackModal'
          data-testid='chatbot-feedback-modal'
          ref={modalRef}
        >
          <form onSubmit={handleSubmit} className='feedbackModal__form'>
            <button
              type='button'
              className='feedbackModal__form__close'
              onClick={onClose}
              aria-label='Close feedback modal'
            >
              <SvgClose className='feedbackModal__form__close__icon' />
            </button>
            <p className='feedbackModal__form__title'>
              <SvgThumbsDown className='feedbackModal__form__title__icon' />
              Share your feedback
            </p>
            <label className='feedbackModal__form__checkboxLabel'>
              <Checkbox
                checked={checkboxValues.notHelpful}
                onChange={() => handleCheckboxChange('notHelpful')}
              />
              This isn&apos;t helpful.
            </label>
            <label className='feedbackModal__form__checkboxLabel'>
              <Checkbox
                checked={checkboxValues.notTrue}
                onChange={() => handleCheckboxChange('notTrue')}
              />
              This isn&apos;t true.
            </label>
            <label className='feedbackModal__form__checkboxLabel'>
              <Checkbox
                checked={checkboxValues.harmful}
                onChange={() => handleCheckboxChange('harmful')}
              />
              This is harmful/unsafe.
            </label>
            <div className='feedbackModal__form__inputLabel'>
              <TextArea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className='feedbackModal__form__input'
                placeholder='How could we improve our answer?'
                aria-label='Feedback modal text input'
              />
            </div>
            <button
              className='feedbackModal__form__submit'
              type='submit'
              disabled={
                !Object.values(checkboxValues).some(Boolean) || submitting
              }
            >
              {submitting ? (
                <SvgLoading className='feedbackModal__form__submit__icon' />
              ) : (
                'Submit feedback'
              )}
            </button>
          </form>
        </div>
      </FocusTrap>
      <div
        className='feedbackModal__backdrop'
        data-testid='feedback-modal-backdrop'
      />
    </>,
    document.body
  )
}
