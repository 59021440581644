import './typing-animation.scss'

export default function TypingAnimation() {
  return (
    <div
      data-testid='chatbot-loading-response'
      className='typingBubble'
    >
      <span />
      <span />
      <span />
    </div>
  )
}
